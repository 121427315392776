import { defineComponent, computed, watch, reactive } from '@vue/composition-api';
import Accordion from '@/components/Accordion.vue';
import { useGetEntitiesQuery, LocationType } from '@/generated-types/graphql.types';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
export default defineComponent({
    components: {
        Accordion
    },
    props: {
        selected: {
            type: Number,
            default: null
        },
        isOnRegionPage: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const getEntitiesQueryVariables = reactive({
            entity_type: LocationType.Region
        });
        // Getting all regions from the db (TODO: paginated)
        const { result, refetch } = useGetEntitiesQuery(getEntitiesQueryVariables);
        watch(() => globalThis.$i18n.locale, (curr, prev) => {
            if (!prev)
                return;
            refetch(getEntitiesQueryVariables);
        }, { immediate: true });
        const regions = computed(() => result.value?.get_all_locations.result || []);
        return {
            regions,
            GeoPagesRoutes,
            useGetLocalizedPath
        };
    }
});
