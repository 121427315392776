import { defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
    props: {
        expandedMode: {
            type: Boolean,
            default: false
        },
        noBorder: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const expanded = ref(props.expandedMode);
        return {
            expanded
        };
    }
});
