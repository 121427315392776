import { defineComponent, computed, watch, reactive, ref, onMounted } from '@vue/composition-api';
import PagePagination from '@/components/PagePagination.vue';
import CitiesList from '@/GeoPages/components/CitiesList.vue';
import { useGetEntitiesQuery, LocationType } from '@/generated-types/graphql.types';
import qs from 'query-string';
const PAGE_SIZE = Number(process.env.VUE_APP_SEO_PAGINATION);
export default defineComponent({
    components: {
        CitiesList,
        PagePagination
    },
    setup(_, context) {
        const { root } = context;
        const page = ref(1);
        const getEntitiesQueryVariables = reactive({
            entity_type: LocationType.City,
            pagination: {
                page: page.value,
                size: PAGE_SIZE
            }
        });
        onMounted(() => {
            const currentQueryParams = qs.parse(location.search, {
                arrayFormat: 'bracket'
            });
            page.value =
                currentQueryParams.page && Number(currentQueryParams.page) > 0
                    ? Number(currentQueryParams.page)
                    : 1;
        });
        // Getting all cities from the db (paginated)
        const { result, refetch } = useGetEntitiesQuery(getEntitiesQueryVariables);
        watch(() => globalThis.$i18n.locale, (curr, prev) => {
            if (!prev)
                return;
            refetch(getEntitiesQueryVariables);
        }, { immediate: true });
        // Re-fetch new list of cities for the current page
        watch(() => page.value, (curr, prev) => {
            if (prev && curr !== prev) {
                getEntitiesQueryVariables.pagination.page = page.value;
                refetch(getEntitiesQueryVariables)?.then(result => {
                    if (!result.data.get_all_locations.result.length) {
                        page.value = 1;
                    }
                });
                history.replaceState({}, null, page.value === 1 ? window.location.pathname : `?page=${page.value}`);
                root.$scrollTo('#cities-block', 500, {
                    offset: -100
                });
            }
        }, { immediate: true });
        const cities = computed(() => result.value?.get_all_locations.result || []);
        const pagesCount = computed(() => result.value?.get_all_locations.total_pages);
        return {
            page,
            cities,
            pagesCount
        };
    }
});
