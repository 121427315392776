import { defineComponent } from '@vue/composition-api';
import CityCard from '@/GeoPages/components/CityCard.vue';
import EmptyList from '@/GeoPages/components/EmptyList.vue';
export default defineComponent({
    components: {
        CityCard,
        EmptyList
    },
    props: {
        cities: {
            type: Array,
            default: () => []
        }
    }
});
