import { computed, defineComponent, onMounted, watch } from '@vue/composition-api';
import ListDropdown from '@/uikit/ListDropdown.vue';
import ListItem from '@/uikit/ListItem.vue';
export default defineComponent({
    components: {
        ListDropdown,
        ListItem
    },
    props: {
        questions: {
            type: Array,
            default: () => []
        }
    },
    setup(props, context) {
        const { emit } = context;
        const structuredDataFAQ = computed(() => {
            return {
                '@type': 'FAQPage',
                mainEntity: props.questions.map((question) => ({
                    '@type': 'Question',
                    name: question.question,
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: question.answer
                    }
                }))
            };
        });
        watch(() => props.questions, () => {
            emit('structured-data-ready', structuredDataFAQ.value);
        });
        onMounted(() => {
            emit('structured-data-ready', structuredDataFAQ.value);
        });
        return {};
    }
});
