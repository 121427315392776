import { defineComponent, computed } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
export default defineComponent({
    components: {},
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        /**
         * Get random image from the city locations
         */
        const imageUrl = computed(() => {
            const cityVenues = props.value.venues?.result || [];
            const randomVenue = cityVenues[Math.floor(Math.random() * cityVenues.length)];
            const venueMainImage = randomVenue?.venue_images.find(image => image && image.order === 0);
            return venueMainImage
                ? `${randomVenue?.venue_images.find(image => image && image.order === 0)
                    ?.image_url}/tr:w-300,h-230`
                : '/img/common/placeholder-image.png';
        });
        return {
            imageUrl,
            GeoPagesRoutes,
            useGetLocalizedPath
        };
    }
});
